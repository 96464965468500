import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { PENDING_DOCUMENTS_INVOICES } from '@fingo/lib/graphql';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import Stack from '@mui/material/Stack';
import EXPIRE_PENDING_DOCUMENTS from '../../../graphql/pending-documents/expirePendingDocuments';

const ExpirePendingDocumentsReviewDialog = ({ selectedInvoices }) => {
  const [comments, setComments] = useState('');
  const [open, toggleDialog] = useBooleanState(false);
  const { addAlert } = useSnackBars();
  const count = selectedInvoices.length;
  const [evaluationRequest, { loading }] = useMutation(
    EXPIRE_PENDING_DOCUMENTS,
    {
      variables: { invoiceIds: selectedInvoices, comments },
      onCompleted: () => {
        addAlert({
          id: 'lucila-evaluation-request',
          message: 'Facturas expiradas exitosamente',
        });
        toggleDialog();
      },
      refetchQueries: [PENDING_DOCUMENTS_INVOICES],
    },
  );
  return (
    <>
      <Tooltip title={selectedInvoices.length === 0 ? 'Selecciona facturas' : 'Expirar'}>
        <div>
          <IconButton
            onClick={toggleDialog}
            disabled={selectedInvoices.length === 0}
            color="primary"
          >
            <PlaylistRemoveIcon fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>
      <FingoDialog
        open={open}
        handleClose={toggleDialog}
        dialogActionButton={(
          <LoadingButton
            variant="contained"
            size="small"
            onClick={evaluationRequest}
            loading={loading}
          >
            Expirar
          </LoadingButton>
        )}
      >
        <Stack direction="column" spacing={2}>
          <Typography variant="h4" textAlign="center">
            {`¿Seguro que quieres expirar ${count > 1 ? 'estas' : 'esta'} ${count > 1 ? count : ''} ${count > 1 ? 'facturas' : 'factura'}?`}
          </Typography>
          <TextField
            fullWidth
            label="Comentarios"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            multiline
            rows={2}
          />
        </Stack>
      </FingoDialog>
    </>
  );
};

ExpirePendingDocumentsReviewDialog.propTypes = {
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ),
};

ExpirePendingDocumentsReviewDialog.defaultProps = {
  selectedInvoices: [],
};

export default ExpirePendingDocumentsReviewDialog;
