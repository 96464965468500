import dayjs from '@fingo/lib/config/dayjs';
import { customPriceDifferenceColumn, useInvoicePreColumns } from '@fingo/lib/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { buildInvoicesDataToSimulate } from '../../../../helpers';
import FactoringSimulationDataGrid from './FactoringSimulationDataGrid';

const InvoiceFactoringSimulationGrid = ({
  invoicesDataToSimulate,
  setInvoicesDataToSimulate,
  columns,
}) => {
  const buildInitialOffer = (invoice) => {
    const offer = { invoiceId: invoice.id };
    if (
      invoice.dateToPay
      && dayjs(invoice.dateToPay, 'YYYY-MM-DD', true).isValid()
    ) {
      offer.dateToPay = dayjs(invoice.dateToPay);
    }
    return offer;
  };

  const handleInvoiceDateChange = (invoicesData, datePayData) => {
    const dataToUpdate = [...invoicesData];
    datePayData.forEach((invoice) => {
      const indexToUpdate = dataToUpdate.findIndex(
        (inv) => inv.id === invoice.invoiceId,
      );
      if (invoice.dateToPay) {
        dataToUpdate[indexToUpdate].dateToPay = dayjs(invoice.dateToPay);
        dataToUpdate[indexToUpdate].days = dayjs(invoice.dateToPay).diff(
          dayjs().startOf('day'),
          'days',
        );
      }
    });
    return buildInvoicesDataToSimulate(dataToUpdate);
  };

  return (
    <FactoringSimulationDataGrid
      invoicesDataToSimulate={invoicesDataToSimulate}
      setInvoicesDataToSimulate={setInvoicesDataToSimulate}
      includeHeaders={columns}
      gridColumns={useInvoicePreColumns(customPriceDifferenceColumn())}
      buildInitialOffer={buildInitialOffer}
      onInvoiceDateChange={handleInvoiceDateChange}
    />
  );
};

InvoiceFactoringSimulationGrid.propTypes = {
  invoicesDataToSimulate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      folio: PropTypes.string,
    }),
  ),
  setInvoicesDataToSimulate: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
};

InvoiceFactoringSimulationGrid.defaultProps = {
  invoicesDataToSimulate: [],
};

export default InvoiceFactoringSimulationGrid;
