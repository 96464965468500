import { useReactiveVar } from '@apollo/client';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const FactoringSimulationDataGrid = ({
  invoicesDataToSimulate,
  setInvoicesDataToSimulate,
  includeHeaders,
  gridColumns,
  buildInitialOffer,
  onInvoiceDateChange,
}) => {
  useEffect(() => {
    const initialOffers = invoicesDataToSimulate.map(buildInitialOffer);
    shoppingCartOffersVar(initialOffers);
  }, []);

  const invoicesDateToPay = useReactiveVar(shoppingCartOffersVar);
  useEffect(() => {
    const updatedData = onInvoiceDateChange(invoicesDataToSimulate, invoicesDateToPay);
    setInvoicesDataToSimulate(updatedData);
  }, [invoicesDateToPay]);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        minWidth: '700px',
        flex: 1,
      }}
    >
      <FingoDataGrid
        trackerId="PREOFFERS-MANAGER"
        type="available-factoring"
        rows={invoicesDataToSimulate}
        rowCount={invoicesDataToSimulate.length}
        page={0}
        pageSize={invoicesDataToSimulate.length}
        hideFooter
        serverFilters={false}
        columns={gridColumns}
        selectionModel={invoicesDataToSimulate.map((inv) => inv.id)}
        parentBoxHeight="100%"
        includeHeaders={includeHeaders}
      />
    </Box>
  );
};

FactoringSimulationDataGrid.propTypes = {
  invoicesDataToSimulate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      folio: PropTypes.string,
    }),
  ),
  includeHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  setInvoicesDataToSimulate: PropTypes.func.isRequired,
  gridColumns: PropTypes.node.isRequired,
  buildInitialOffer: PropTypes.func.isRequired,
  onInvoiceDateChange: PropTypes.func.isRequired,
};

FactoringSimulationDataGrid.defaultProps = {
  invoicesDataToSimulate: [],
};

export default FactoringSimulationDataGrid;
