import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetUser } from '@fingo/lib/hooks';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useMutation } from '@apollo/client';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UNEXPIRE_INVOICES } from '../../../graphql/invoices-manager/mutations';

const UnexpireInvoicesAction = ({ selectedInvoices, setSelectedInvoiceIds }) => {
  const user = useGetUser();

  const expiredOrRejectedInvoices = useMemo(
    () => selectedInvoices.filter((inv) => ['REJECTEDPREOFFEREVALUATION', 'EXPIRED', 'PENDINGDOCUMENTSEXPIRED'].includes(inv.status)),
    [selectedInvoices],
  );

  const [unexpireInvoices, { loading }] = useMutation(UNEXPIRE_INVOICES, {
    variables: {
      invoiceIds: expiredOrRejectedInvoices.map((invoice) => invoice.id),
    },
    onCompleted: () => {
      setSelectedInvoiceIds([]);
    },
  });

  const canUnexpireInvoice = useMemo(
    () => userHasRoutePermission(user, 'invoices.unexpire_invoice'),
    [user],
  );

  const isDisabled = useMemo(() => {
    if (!canUnexpireInvoice || selectedInvoices.length === 0) return true;
    return expiredOrRejectedInvoices.length !== selectedInvoices.length;
  }, [canUnexpireInvoice, selectedInvoices]);

  const tooltipTitle = useMemo(() => {
    if (!canUnexpireInvoice) return 'Solamente los administradores pueden deshacer una expiración';
    if (selectedInvoices.length === 0) return 'Selecciona facturas';
    if (isDisabled) return 'Debes seleccionar facturas expiradas o rechazadas por riesgo';
    return null;
  }, [canUnexpireInvoice, selectedInvoices.length, isDisabled]);

  return (
    <Tooltip title={tooltipTitle}>
      <div>
        <MenuItem
          onClick={unexpireInvoices}
          disabled={isDisabled}
          loading={loading}
        >
          <ListItemIcon>
            <ArrowBackIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Volver a riesgo</ListItemText>
        </MenuItem>
      </div>
    </Tooltip>
  );
};

UnexpireInvoicesAction.propTypes = {
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.status,
    }),
  ),
  setSelectedInvoiceIds: PropTypes.func.isRequired,
};

UnexpireInvoicesAction.defaultProps = {
  selectedInvoices: [],
};

export default UnexpireInvoicesAction;
