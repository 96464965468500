import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HistoryIcon from '@mui/icons-material/History';
import { useMutation } from '@apollo/client';
import { FETCH_MANY_TRACE, MY_ASSIGNMENTS } from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';
import Box from '@mui/material/Box';

const FetchOperatedTrace = ({ selectedInvoices, setSelectedInvoiceIds, setAnchorEl }) => {
  const { addAlert } = useSnackBars();
  const [fetchManyTraceMutation, { loading: mutationLoading }] = useMutation(FETCH_MANY_TRACE, {
    variables: { invoiceId: selectedInvoices.map((invoice) => invoice.id) },
    onCompleted: () => {
      addAlert({
        id: 'invoice-action-completed',
        color: 'success',
        message: 'Estado de la cesión actualizado',
      });
      setSelectedInvoiceIds([]);
      setAnchorEl(null);
    },
    refetchQueries: [MY_ASSIGNMENTS],
    onError: ({ message }) => addAlert({
      id: 'invoice-action-error',
      color: 'error',
      message,
    }),
  });

  const handleClick = () => {
    if (selectedInvoices.length === 0) return;
    fetchManyTraceMutation();
  };

  return (
    <Tooltip title={(selectedInvoices.length === 0 && 'Selecciona facturas')}>
      <Box>
        <MenuItem
          onClick={handleClick}
          disabled={selectedInvoices.length === 0 || mutationLoading}
        >
          <ListItemIcon>
            <HistoryIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {mutationLoading ? 'Consultando...' : 'Actualizar estado cesión'}
          </ListItemText>
        </MenuItem>
      </Box>
    </Tooltip>
  );
};

FetchOperatedTrace.propTypes = {
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      company: PropTypes.shape({
        masterEntity: PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.string,
        }),
      }),
    }),
  ),
  setSelectedInvoiceIds: PropTypes.func.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
};

FetchOperatedTrace.defaultProps = {
  selectedInvoices: [],
};

export default FetchOperatedTrace;
